import moment from 'moment'
import "owl.carousel"

jQuery(document).ready(function ($) {


  // MOBILE MENU
  $('.mobile-menu-icon').on('click', function () {
    $("header").toggleClass('active')
    $("#nav-social").toggleClass('active')
    $(".menu-wrapper").toggleClass('active')
    $(".line").toggleClass('active')
  })

  $('.nav-button').on('click', function () {
    if ($('#nav-menu').hasClass('active')) {
      $("header").toggleClass('active')
      $("#nav-menu").toggleClass('active')
      $("#nav-social").toggleClass('active')
      $(".menu-wrapper").toggleClass('active')
      $(".line").toggleClass('active')
    }
  })


  // MUSIC CAROUSEL
  function createMusicCarousel() {
    const musicCarousel = $("#music-carousel")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    musicCarousel.owlCarousel({
      loop: true,
      nav: true,
      dots: false,
      items: 1,
      startPosition: 0,
      autoplayTimeout: 3000,
      autoplaySpeed: 500,
      margin: 55,
      center: true,
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createMusicCarousel()

  // VIDEO CAROUSEL
  function createVideoCarousel() {
    const videoCarousel = $("#video-carousel")
    const leftArrow = '<div class="owl-custom-nav-prev arrow"><i class="fa-solid fa-chevron-left"></i></div>'
    const rightArrow = '<div class="owl-custom-nav-next arrow"><i class="fa-solid fa-chevron-right"></i></div>'

    videoCarousel.owlCarousel({
      loop: true,
      nav: true,
      dots: false,
      items: 1,
      startPosition: 0,
      autoplayTimeout: 3000,
      autoplaySpeed: 500,
      margin: 55,
      center: true,
      responsive: {
        0: {
          stagePadding: 50,
          margin: 20
        },
        700: {
          stagePadding: 200,
          margin: 55
        }
      },
      navText: [
        leftArrow,
        rightArrow
      ]
    })
  }
  createVideoCarousel()


  // Tourdate Import
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_15560668/events?app_id=45PRESS_kat_luna',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      const events = $('#tour-dates');
      let html = '';
      let n = 0;
      let maxVisibleDates = 9;

      if (data.length) {
        for (let event of data) {
          n++;
          let hideClass = n > maxVisibleDates ? 'hidden-event' : '';
          html += '<div class="event-group ' + hideClass + '">';
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD').toUpperCase() + '</div>';
          html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>';
          html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>';
          html += '<div class="event-links">';
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="link btn">' + offer.type.toUpperCase() + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }

        events.html(html);
        $("#toggle-dates").hide()

        if (n > maxVisibleDates) {
          $("#toggle-dates").show();
          $(".hidden-event").hide();
          $("#toggle-dates").text("Show More");
        } else {
          $("#toggle-dates").hide();
        }

      } else {
        events.html('<span class="no-events">Check back soon for more updates!</span>');
      }
    }
  });

  // Toggle Dates Functionality
  $("#toggle-dates").click(function (event) {
    event.preventDefault();

    const hiddenEvents = $(".hidden-event");
    if (hiddenEvents.is(":visible")) {
      hiddenEvents.slideUp();
      $(this).text("Show More Dates");
    } else {
      hiddenEvents.slideDown();
      $(this).text("Show Less Dates");
    }
  });



  // Form Logic
  $("#kat-luna-email-sign-up").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    console.log("Form data being submitted:", data);
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('#form-container').html('<h3>Thank you for signing up!</h3>')
        // alert("Submitted!");
        console.log("success")
      },
      error: function (err) {
        console.log("Error details: ", err);
        alert("An error has occurred!");
      }
    });
  });

});